/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash-es/isEmpty';
import useTracking from 'components/Globals/Analytics';
import cn from 'src/classnames';
import { useTrackingClickStyles } from 'components/Globals/TrackingTester/TrackingTester';
import { Link } from '../../src/i18n';
import styles from './NextLink.module.scss';

const NextLink = ({
  href,
  children,
  className,
  target,
  disabled,
  rel,
  startIcon,
  type,
  underlined,
  title,
  trackLink,
  ariaLabel,
  style,
  stopPropagation,
  vwoLinkId,
  skipTracking = false,
  trackingData,
  ...rest
}) => {
  const trackingClasses = useTrackingClickStyles(trackingData, skipTracking);
  const linkTitle = title || (typeof children === 'string' ? children : null);
  const track = useTracking();

  const handleAnchorClick = e => {
    if (!_isEmpty(trackingData)) {
      track.click(trackingData);
    }
    if (typeof trackLink === 'function') {
      trackLink();
    }
    if (stopPropagation) {
      if (e.stopPropagation) {
        e.stopPropagation();
      } else {
        e.cancelBubble = true;
      }
    }
  };
  return href ? (
    <Link href={href} prefetch={false} {...rest}>
      <a
        title={linkTitle}
        className={cn(styles.link, className, trackingClasses, {
          [styles.disabled]: disabled,
          [styles.textType]: type === 'text',
          [styles.iconLink]: Boolean(startIcon),
          [styles.underlined]: underlined,
        })}
        style={{ ...style }}
        target={target}
        rel={rel}
        onClick={e => handleAnchorClick(e)}
        aria-label={ariaLabel}
        id={vwoLinkId}
      >
        {startIcon && <span className={styles.iconBox}>{startIcon}</span>}
        {children}
      </a>
    </Link>
  ) : (
    <></>
  );
};

NextLink.propTypes = {
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  children: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  rel: PropTypes.string,
  startIcon: PropTypes.element,
  target: PropTypes.string,
  type: PropTypes.oneOf(['text', 'link']),
  underlined: PropTypes.bool,
  title: PropTypes.string,
  trackLink: PropTypes.func,
  ariaLabel: PropTypes.string,
};

NextLink.defaultProps = {
  disabled: false,
  rel: '',
  target: '_self',
  type: 'link',
  underlined: false,
};

export default NextLink;
