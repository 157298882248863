import { createSelector } from 'reselect';
import find from 'lodash/find';
import get from 'lodash/get';
import { createSelectFieldOptions } from 'utils/globals/app';
import { initialAppState } from './reducer';

const selectGlobalDomain = state => state.global || initialAppState;

const selectCountries = () => createSelector(selectGlobalDomain, subState => get(subState, 'countries', []));
const selectLanguage = () => createSelector(selectGlobalDomain, subState => get(subState, 'language', []));

const selectCountriesOptions = () =>
  createSelector(selectGlobalDomain, subState =>
    createSelectFieldOptions(get(subState, 'countries'), 'id', 'name', {}, true),
  );

const selectCountriesRegionsOptions = () =>
  createSelector(selectGlobalDomain, subState => {
    const regionsOptions = createSelectFieldOptions(get(subState, 'regions.data'), 'id', 'name', { type: 'region' });
    const countriesOptions = createSelectFieldOptions(get(subState, 'countries'), 'id', 'name', { type: 'country' });
    return [...regionsOptions, ...countriesOptions];
  });

const selectCountryByCode = countryCode =>
  createSelector(selectCountries(), countries =>
    find(countries, country => get(country, 'countryCode') === countryCode),
  );

const selectError = () => createSelector(selectGlobalDomain, subState => get(subState, 'error'));
const selectTabScrollPosition = () => createSelector(selectGlobalDomain, subState => get(subState, 'scrollPosition'));

const selectList = (domain, listName) => createSelector(domain, subState => get(subState, listName));
const selectListData = (domain, listName) => createSelector(selectList(domain, listName), list => get(list, 'data'));
const selectListLoading = (domain, listName) =>
  createSelector(selectList(domain, listName), list => get(list, 'loading'));

const selectUserConstants = () => createSelector(selectGlobalDomain, subState => get(subState, 'userConstants'));
const selectRegions = () =>
  createSelector(selectGlobalDomain, subState =>
    createSelectFieldOptions(get(subState, 'regions.data'), 'id', 'name', { type: 'region' }),
  );

export {
  selectCountryByCode,
  selectCountries,
  selectCountriesOptions,
  selectCountriesRegionsOptions,
  selectError,
  selectList,
  selectListData,
  selectListLoading,
  selectUserConstants,
  selectRegions,
  selectLanguage,
  selectTabScrollPosition,
};
