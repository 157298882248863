import React, { createContext, useContext, useState } from 'react';

const initialData = {
  isOpen: false,
  imageUrl: '',
  title: '',
  message: '',
  actions: [],
  callback: () => {},
  onActionClick: () => {},
};

export const IntermediatePopupContext = createContext(initialData);

const IntermediatePopupProvider = props => {
  const [intermediatePopup, setIntermediatePopup] = useState(initialData);

  const dataValue = { intermediatePopup, setIntermediatePopup };

  return <IntermediatePopupContext.Provider value={dataValue} {...props} />;
};

export const useIntermediatePopup = () => useContext(IntermediatePopupContext);

export default IntermediatePopupProvider;
